
.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-align-justify {
  text-align: justify;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

.text-bold {
  font-weight: 700;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-inline {
  display: inline-block;
}

.text-link {
  color: #007bff;
  text-decoration: underline;
}

.no-break {
  white-space: nowrap;
}
