.rainbow {
  animation: rainbow-effect 10s linear infinite;
}

.blue-blink {
  animation: blue-blink 2s ease-in-out alternate infinite;
}

.blue-blink-delayed {
  animation: blue-blink 1s ease-in-out alternate infinite;
  animation-delay: 1s;
}

.float {
  animation: float 1s ease-in-out alternate infinite;
}

.spin-rainbow {
  animation: spin 10s linear infinite, rainbow-effect 10s linear infinite;
}

@keyframes float {
  from {
    transform: translateY(0);
  } to {
    transform: translateY(-2px);
  }
}

@keyframes blue-blink {
  from {
    color: rgb(3, 159, 221)
  } to {
    color: rgb(2, 68, 153)
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rainbow-effect {
  0% {
    color: #9400D3;
  }

  15% {
    color: #4B0082;
  }

  30% {
    color: #0000FF;
  }

  45% {
    color: #00FF00;
  }

  60% {
    color: #FFFF00;
  }

  75% {
    color: #FF7F00;
  }

  90% {
    color: #FF0000;
  }

  100% {
    color: #9400D3;
  }

}
