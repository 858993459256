.close-button {
  color: $gray;
  position: absolute;
  right: 1em;
  top: 1em;

  &:hover {
    color: $medium-gray;
  }
}

.close-btn-fixed {
  color: $gray;
  position: fixed;
  right: 1em;
  top: 1em;

  &:hover {
    color: $medium-gray;
  }
}
