.sub-menu {
  align-items: center;
  background-color: $color-primary;
  display: flex;
  height: 4em;
  margin-bottom: 1em;
  padding: 0.75em 0.5em;
  width: 100%;

  .heart {
    color: $white;
    position: relative;
  }

  .heart.active::after {
    background-color: $white;
    border-radius: 2px;
    bottom: -0.75em;
    content: '';
    height: 5px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .heart:hover {
    filter: brightness(90%);
  }

  &.light {
    background-color: $color-primary-50;
  }
}

.sub-menu__item {
  align-items: center;
  background-color: transparent;
  display: flex;
  height: 2.5em;
  justify-content: center;
  margin: 0 0.5em;
  position: relative;
  width: 2.5em;

  &:hover {
    filter: brightness(90%);
  }

  &.active::after {
    background-color: $white;
    border-radius: 5px;
    bottom: -0.75em;
    content: '';
    height: 4px;
    position: absolute;
    width: 100%;
  }
}

