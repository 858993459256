// =============  GLOBAL  ===============

// ===== Form styles ====>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.formItemStyle {
    display: flex;
    justify-content: space-between;
}

.formItemStyle *::placeholder {
    font-size: 1.3rem;
}

.formItemStyle input,
.formItemStyle select,
.formItemStyle textarea {
    min-height: 2.8rem;
    font-size: 1.3rem;
    border-radius: 4px;
    border: none;
    box-sizing: border-box;
    padding: .5rem 1rem;
    color: rgb(2, 86, 114);
}

// ===== Social media styles ==== >
.socialMediaContainer {
    color: white;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    margin: 1rem .5rem;
}

.socialMediaContainer:hover {
    cursor: pointer;
}

.socialMediaContainer:hover .socialMediaLabel {
    width: 12rem;
}

.socialMediaIcon {
    transition: transform 1.2s ease, color 1s linear;
    transform: rotate(0deg);
}

.socialMediaContainer:hover .socialMediaIcon {
    transform: rotate(360deg);
}

.socialMediaContainer:hover .instagramColor {
    color: rgb(197, 84, 133);
}

.socialMediaContainer:hover .websiteColor {
    color: orange;
}

.socialMediaContainer:hover .whatsappColor {
    color: rgb(37,200,98);
}

.socialMediaContainer:hover .youtubeColor {
    color: rgb(189, 92, 92);
}


// ===== Button styles ====>

.alertBtn1:hover, .alertBtn2:hover {
    background-color: rgb(85, 197, 235);
}

//   === Shooting stars ===>

.shootingStar {
    position: absolute;
    width: 4px;
    height: 4px;
    background: #fff;
    top: -5%;
    left: -5%;
    border-radius: 50%;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1),
                0 0 0 8px rgba(255, 255, 255, 0.1),
                0 0 0 20px rgba(255, 255, 255, .1);
    animation: shooting 10s linear infinite;
}

.shootingStar::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 300px;
    height: 1px;
    background: linear-gradient(90deg, #fff, transparent);
}

@keyframes shooting {
    0% {
        transform: rotate(225deg) translateX(100vh);
        opacity: 1;
    } 70% {
        transform: rotate(225deg) translateX(100vh);
        opacity: 1;
    } 85% {
        opacity: 1;
    }100% {
        opacity: 0;
        transform: rotate(225deg) translateX(-145vh);
    }
}

// ============= TEMPLATE =================

@keyframes starblink {
    from {
        opacity: 0;
    } to {
        opacity: .7;
    }
}

.templateHamburgerMenu {
    color: white;
    font-size: 2rem;
    transform: rotate(0deg);
    transition: color 1s ease-in-out, transform .5s ease-in-out;
}

.templateHamburgerMenu:hover {
    cursor: pointer;
    color: #8CCAFF;
    transform: rotate(360deg);
}

.left-compass-detail {
  animation: compass_spin 10s alternate infinite;
  animation-timing-function: ease-in-out;

}

@keyframes compass_spin {
  0% {
    transform: rotate(0deg);
  } 20% {
    transform: rotate(270deg);
  } 40% {
    transform: rotate(30deg);
  } 50% {
    transform: rotate(180deg);
  } 60% {
    transform: rotate(20deg);
  } 70% {
    transform: rotate(45deg);
  } 80% {
    transform: rotate(65deg);
  } 90% {
    transform: rotate(35deg);
  } 100% {
    transform: rotate(360deg);
  }
}

.icon-to-be-rotated {
  transform: rotate(0deg);
  transition: transform 0.4s;
}

.rotate-icon {
  transform: rotate(180deg);
  transition: transform 0.4s;
}
