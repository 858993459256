.weekly-achievement {

  &-icon-style {
    background-color: $light-gray;
    margin-right: 0.7rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-primary;
    border-radius: 50%;
  }
}

@keyframes goldenTrophyEffect {
  from {
    filter: contrast(100%);
  } to {
    filter: contrast(130%);
  }
}

.golden-trophy-effect {
  animation: goldenTrophyEffect infinite 1s linear alternate;

}
