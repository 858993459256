.inline {
  display: inline;
}

.inline p:last-of-type {
  display: inline;
}

.inline > p {
  margin-bottom: 10px;
}
