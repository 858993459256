@import 'WeekMenu';
@import 'SubMenu';

.orange-blink-animation {
  animation: orange-blink-animation ease-in-out alternate infinite 1s;
}

@keyframes orange-blink-animation {
  from {
    background-color: #0473e3;
  } to {
    background-color: #e35904;
  }
}
