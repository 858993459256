.enter-page {
  background-attachment: fixed;
  background-image: url('/images/fundo.png');
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: auto 100vh;
  max-width: 100vw;
  min-height: 100vh;

  @include device(sm3) {
    background-position-x: calc(100% + 150px);
    background-position-y: 120px;
  }
}

.enter-container {
  margin: 0 auto;
  max-width: 960px;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 80px;

  @include device(sm3) {
    padding-top: 20px;
  }
}

.enter-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5em;

  @include device(sm3) {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2em;

    img {
      margin-bottom: 1em;
    }

    h3 {
      text-align: center;
    }
  }
}

.bg-medic {
  background-image: url('/images/bg-medic.svg');
  background-size: 110%;
}

.shadow-card {
  border-radius: 4px;
  box-shadow: 0 0 8px 2px #0005;
  overflow: hidden;
  width: 100%;

  @include device(sm3) {
    margin: 0 auto;
    max-width: 480px;
  }

  .content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @include device(sm3) {
      grid-template-columns: minmax(0, 1fr);
    }
  }
}

.warning-msg {
  background-color: $color-warning-light;
  border: 1px solid $color-warning;
  border-radius: 4px;
  padding: 0.5em;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.visible {
  visibility: visible;
  opacity: 1;
}

.fade {
  transition: opacity 1.5s ease-in;
  z-index: 2;
}

.spin-logo {
  transform-origin: center;
  animation-name: rotateLogo;
  animation-fill-mode: forwards;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

@keyframes rotateLogo {
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }

}
