/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;

  /* Tooltip text */
  & .tooltiptext {
    background-color: #555;
    color: #fff;
    font-size: 0.9rem;
    text-align: justify;
    padding: 5px;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);

    /* Fade in tooltip */
    @include hide;
  }

  /* Tooltip arrow */
  & .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  &:hover .tooltiptext {
    @include show;
  }

  @mixin hide {
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s linear;
    visibility: hidden;
  }

  @mixin show {
    opacity: 1;
    transition: opacity 0.3s linear;
    visibility: visible;
  }
}
