.header {
  background-color: $color-primary;
  color: $white;
  height: 104px;

  &__container {
    align-items: flex-end;
    background-color: $color-primary;
    display: flex;
    height: 100%;
    padding-bottom: 1.5em;
  }

  &.light,
  &.light &__container {
    background-color: $color-primary-50;
  }
}

.course-select {
  // width: 250px;
  // margin-right: 1.5em;
  padding-right: 1.5em;
  background: none !important;
}

@media print {
  .header {
    display: none;
  }
}
