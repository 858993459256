.input-container {
  height: 2em;
  position: relative;
  width: 100%;
}

.jj-input {
  border: 1px solid rgba(#000, 0.5);
  border-radius: 4px;
  height: 100%;
  padding: 0 0.8em;
  width: 100%;
}

.jj-input:focus {
  border: 2px solid $color-primary;
  outline: 0;
}

.jj-input:required:not(:placeholder-shown):invalid {
  border: 2px solid rgba($color-danger, 0.7);
  outline: 0;
}

.input-suffix {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 2em;
}
