.ring {
  margin: 1rem auto;
  stroke: $color-primary;

  > .text {
    text-anchor: middle;
    stroke: none;
    font-weight: 900;
    fill: rgba($black, 0.4);
  }

  > .base-ring {
    stroke: inherit
  }

  > .accomplished-ring {
    transition: 1s stroke-dashoffset cubic-bezier(.55,.01,.2,1) 1s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke-dashoffset: 0;
    stroke: inherit;
  }
}
