@import './lib/styles/styles';
@import './views/_components/all';
@import './views/enter/Enter';
@import './views/agenda/_components/all';
@import './views/profile/_components/all';
@import './views/performance/_components/all';
@import './views/chat-bot/_components/all';
@import './views/create-activity/CreateActivity';
@import './views/theme-list/ThemeList';
// @import './views/jj+/JJPlus';
@import './views/diagnosis/all';
@import './views/_components/Forum/scss/all';
@import 'views/flashcards-creation/style/Flashcards.scss';
@import './views/agenda/aristo-cup/aristo-cup.scss';

#floating_button_sirena {
  background-color: #0473E3 !important;
  position: fixed !important;
  z-index: 1 !important;
}

#widget_logo {
  position: initial !important;
  margin: auto;
}

@media (max-width: 768px) {
  #floating_button_sirena {
    bottom: 60px !important;
    right: 10px !important;
    min-width: 2rem !important;
    max-height: 2rem !important;
  }

  #widget_logo {
    width: 1.1rem !important;
    top: .45rem;
    left: .45rem;
    margin-top: .45rem;
  }
}

@media (min-width: 768px) {
  #widget_logo {
    width: 2rem !important;
    top: .85rem;
    left: .85rem;
    margin-top: .8rem;
  }
}

.powered-by {
  display: none !important;
}

.weekly-progress-circle > svg > circle:first-child {
  stroke-width: 2px !important;
}

@media print {
  #sirena_button {
    display: none;
  }
}

.loading-bar-animation {
  animation: loadingBarAnimation alternate infinite .8s ease-in-out;
}

@keyframes loadingBarAnimation {
  from {
    // background-color: #D53F8C;
    background-color: #002e7f;
  } to {
    // background-color: #DD6B20;
    background-color: #0473e3;
  }

}
