button::-moz-focus-inner {
  border: 0;
}

button:active,
button:focus {
  outline: none;
}

button {
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding: 0;
  text-transform: inherit;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.jj-button {
  align-items: center;
  border-radius: 4px;
  color: $white;
  cursor: pointer;
  display: flex;
  font-size: inherit;
  font-weight: 700;
  height: 2em;
  justify-content: center;
  padding: 0 1em;

  &:not(:disabled):hover{
    filter: brightness(90%);
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}

