select {
  appearance: none;
  border: 1px solid $dark-gray;
  border-radius: 4px;
  font-family: inherit;
  font-size: inherit;
  height: 2em;
  outline: $color-primary;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
}

// select:not(:disabled) {
//   background-color: transparent;
// }

select:focus {
  border-color: $color-primary;
  border-width: 2px;
}
