.loading-circle {
  animation: blink .5s infinite ease-in-out alternate;
  filter: blur(5px)
}

.bounce-up {
  animation: bounce-up 3s infinite ease-in-out alternate;
}

.bounce-down {
  animation: bounce-down 3s infinite ease-in-out alternate;
}

@keyframes bounce-up {
  50% {
    transform: translateY(-4px);
  }
}

@keyframes bounce-down {
  50% {
    transform: translateY(4px);
  }
}

@keyframes blink {
  from {
    filter: brightness(1);
  } to {
    filter: brightness(1.5);
  }
}
