.main {
  min-height: 100vh;

  &__container {
    margin: 0 auto;
    min-width: 1016px;

    @include device(xs) {
      width: calc(100%);
    }

    @include device(sm) {
      width: calc(100%);
    }

    @include device(md) {
      width: calc(100%);
    }

    @include device(lg) {
      max-width: 1016px;
      width: calc(100%);
    }

    @include device(xl) {
      width: 1016px;
    }

    @include device(xxl) {
      width: 1016px;
    }
  }
}
