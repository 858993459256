.overlay {
  align-items: center;
  background-color: rgba($black, 0.5);
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  overflow: auto;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;

  @include device(xs) {
    padding-top: 3em;
  }

  @include device(sm) {
    padding-top: 3em;
  }

  &.visible {
    @include show;
  }

  &.hidden {
    @include hide;
  }

  &__content {
    padding: 6em 4em 4em;
    z-index: 100;
  }
}
