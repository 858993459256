
.tag {
  align-items: center;
  background-color: $white;
  border-bottom: 2px solid $medium-gray;
  color: $color-primary;
  display: flex;
  font-weight: 700;
  height: 4em;
  padding: 1em;
}

.tag.active {
  background-color: $color-primary;
  color: $white;
  position: relative;

  &::after {
    background-color: inherit;
    content: '';
    height: 20px;
    position: absolute;
    right: -10px;
    transform: rotate(45deg);
    width: 20px;
  }
}

.border-left-gray {
  border-left: 2px solid $medium-gray;
}
