.confirm-modal {
  background-color: rgba($black, 0.9);
  padding: 3em 2em 2em;
  position: relative;
  width: 400px;

  &__close {
    color: $color-primary;
    position: absolute;
    right: 1em;
    top: 1em;
  }

  &.active {
    display: block;
  }
}
