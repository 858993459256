.chat-bubble {
  animation: grow 0.5s;
  position: relative;
  z-index: 1;

  &.wing-left::before,
  &.wing-right::before {
    background-color: inherit;
    content: '';
    height: 30px;
    position: absolute;
    top: 0;
    width: 30px;
    z-index: -1;
  }

  &.wing-left::before {
    left: 0;
    transform: skew(45deg);
  }

  &.wing-right::before {
    right: 0;
    transform: skew(-45deg);
  }
}

@keyframes grow {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
