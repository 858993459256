.tw-line {
  align-items: center;
  box-shadow: 0 0 8px rgba($medium-gray, 1);
  display: flex;
  height: 3em;
  padding: 0 1em;
}

.grow-1:hover {
  transform: scale(1.1);
}
