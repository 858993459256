.menu-item-button__container {
  align-items: flex-end;
  display: flex;
  margin: 0 0.5em;

  &.active {
    height: 4em;
  }

  &:hover {
    height: 4em;
  }
}

.menu-item-button {
  align-items: center;
  color: $white;
  display: flex;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  height: 2em;
  padding: 0 1em;
  width: 180px;
}
