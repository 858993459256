*,
*::before,
*::after {
  box-sizing: border-box;
  font-kerning: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
}

a {
  color: inherit;
  outline: 0;
  text-decoration: none;
}

label {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.markdown-body {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  white-space: pre-wrap;
  margin: auto;

  p {
    margin-bottom: 1em;
  }

  li {
    margin-bottom: 0.5em;
  }
}

.comment-body {
  font-size: 18px;
}
