.number-span {
  align-items: center;
  background-color: $red;
  border-radius: 10px;
  bottom: -4px;
  color: $white;
  display: flex;
  font-size: 0.65em;
  font-weight: 700;
  height: 14px;
  justify-content: center;
  position: absolute;
  right: -2px;
  width: 14px;
}

.extra-menu {
  background: linear-gradient(to right, $color-primary-light, $color-primary);
  overflow: hidden;
  padding: 0.5em 0 0.3em;
  transition: width 0.5s ease-in-out;
  width: 200px;

  &.hide {
    width: 0;
  }
}

.list-extra-item {
  align-items: center;
  border-bottom: 8px solid $color-primary;
  border-radius: 4px;
  box-shadow: 2px 2px 4px 2px rgba($color-default, 0.3);
  display: flex;
  margin: 0.8em 0;
  padding: 0.5em 1.5em;
  position: relative;
}

.list-extra-item.finished {
  border-color: $color-success;

  .list-extra-child {
    opacity: 0.6;
  }
}

.list-extra-close {
  color: $dark-gray;

  &:not(:disabled):hover {
    color: $medium-gray;
  }
}
