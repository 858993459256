@mixin device($device) {
  @if $device == xxl {
    @media only screen and (min-width: 1600px) {
      @content;
    }
  }
  @else if $device == xl {
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      @content;
    }
  }
  @else if $device == lg {
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      @content;
    }
  }
  @else if $device == md {
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  }
  @else if $device == sm {
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      @content;
    }
  }
  @else if $device == xs {
    @media only screen and (max-width: 575px) {
      @content;
    }
  }
  @else if $device == sm3 {
    @media only screen and (max-width: 991px) {
      @content;
    }
  }
}
