
.m-0 {
  margin: 0.25em;
}

.my-0 {
  margin-bottom: 0.25em;
  margin-top: 0.25em;
}

.mx-0 {
  margin-left: 0.25em;
  margin-right: 0.25em;
}

.mb-0 {
  margin-bottom: 0.25em;
}

.mt-0 {
  margin-top: 0.25em;
}

.mr-0 {
  margin-right: 0.25em;
}

.ml-0 {
  margin-left: 0.25em;
}

.p-0 {
  padding: 0.25em;
}

.pb-0 {
  padding-bottom: 0.25em;
}

.pt-0 {
  padding-top: 0.25em;
}

.pr-0 {
  padding-right: 0.25em;
}

.pl-0 {
  padding-left: 0.25em;
}

.px-0 {
  padding-left: 0.25em;
  padding-right: 0.25em;
}

.py-0 {
  padding-bottom: 0.25em;
  padding-top: 0.25em;
}

@for $i from 1 through 20 {
  .m-#{$i} {
    margin: #{$i * 0.5}em;
  }

  .my-#{$i} {
    margin-bottom: #{$i * 0.5}em;
    margin-top: #{$i * 0.5}em;
  }

  .mx-#{$i} {
    margin-left: #{$i * 0.5}em;
    margin-right: #{$i * 0.5}em;
  }

  .mb-#{$i} {
    margin-bottom: #{$i * 0.5}em;
  }

  .mt-#{$i} {
    margin-top: #{$i * 0.5}em;
  }

  .mr-#{$i} {
    margin-right: #{$i * 0.5}em;
  }

  .ml-#{$i} {
    margin-left: #{$i * 0.5}em;
  }

  .p-#{$i} {
    padding: #{$i * 0.5}em;
  }

  .pb-#{$i} {
    padding-bottom: #{$i * 0.5}em;
  }

  .pt-#{$i} {
    padding-top: #{$i * 0.5}em;
  }

  .pr-#{$i} {
    padding-right: #{$i * 0.5}em;
  }

  .pl-#{$i} {
    padding-left: #{$i * 0.5}em;
  }

  .px-#{$i} {
    padding-left: #{$i * 0.5}em;
    padding-right: #{$i * 0.5}em;
  }

  .py-#{$i} {
    padding-bottom: #{$i * 0.5}em;
    padding-top: #{$i * 0.5}em;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-bottom: auto;
  margin-top: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}
