.sort-btn {
  height: 100%;
  position: relative;
}

.sort-btn.asc::after,
.sort-btn.desc::after {
  background-color: #0473E3;
  content: '';
  height: 5px;
  left: 0;
  position: absolute;
  width: 100%;
}

.sort-btn.asc::after {
  bottom: 2px;
}

.sort-btn.desc::after {
  top: 2px;
}
