@import 'Header';
@import 'VideoList';
@import 'Flashcard';
@import 'Tag';
@import 'DifficultyBlocks';
@import 'Soon';
@import 'Tooltip';
@import 'DoubleRangeSlider';
@import 'BurgerMenu';
@import 'LoadingScreen';
@import 'Banner';
@import 'QuestionCard';

.notificationUnseen {
  background-color: #f7fbff;
  transition: background-color 3s linear;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  scroll-behavior: smooth;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.markdown p{
  margin-bottom: 15px;
  margin-top: 15px;
}

.markdown strong {
  margin-top: 50px;

}
