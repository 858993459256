$colors: (
  'primary-light': #00bae0,
  'light-blue': #b1def3,
  'primary': #009bbb,
  'primary-dark': #0e7893,
  'primary-20': rgba(0,155,187,0.2),
  'success': #55d455,
  'success-light': #e2f0e0,
  'warning': #ebc756,
  'warning-light': #f1ebd2,
  'danger': #eb5757,
  'danger-light': #f6e2e2,
  'black': #000,
  'dark-gray': #8a9b8e,
  'medium-gray': #c7d5cb,
  'light-gray': #eef0ef,
  'white': #fff,
  'video': #d9453c,
  'apostila': #fd823e,
  'mapa': #97578c,
  'flashcard': #3e81a3,
  'question': #8e72cd,
  'red': #eb5757,
  'red-30': #eb575760,
  'gray': #8a9b8e,
  'blue': #0473E3,
  'blue-30': #00bae060,
  'orange': #f2994a,
  'yellow-30': #ffd90060,
  'green': #27ae60,
  'green-30': #55d45560,
  'purple': #bb6bd9,
  'default': #4b4f54,
  'extra-theoretical-review': #dc8598,
  'extra-smart-review': #6fc6c1,
  'extra-question': #656667,
);

@each $name, $color in $colors {
  .border-#{$name} {
    border-color: $color !important;
  }

  .bg-#{$name} {
    background-color: $color !important;
  }

  .color-#{$name} {
    color: $color !important;
  }

  .bg-75-#{$name} {
    background-color: rgba($color, 0.75);
  }

  .bg-25-#{$name} {
    background-color: rgba($color, 0.25);
  }

  .color-hover-#{$name}:hover {
    color: $color !important;
  }

  .bg-hover-#{$name}:hover {
    background-color: $color !important;
  }
}
