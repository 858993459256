.week-menu {
  height: 3em;
  position: relative;
  width: 100%;
}

.week-menu__container {
  display: flex;
  height: 100%;
  justify-content: space-between;
  min-width: 100%;
  position: absolute;
}

.week-menu__item {
  color: $dark-gray;
  font-size: 14px;
  height: 100%;
  padding-bottom: 0.3em;
  padding-top: 0.3em;
  position: relative;
  text-align: center;
  text-transform: capitalize;
  width: calc(100% / 7);

  &.active {
    background-color: $color-primary;
    color: $white;
  }
}

.week-menu__item > button {
  height: 100%;
  width: 100%;
}

.week-menu__item::before,
.week-menu__item::after {
  background-color: $medium-gray;
  content: '';
  height: 50%;
  position: absolute;
  top: 1em;
  width: 1px;
}

.week-menu__item::before {
  left: 0;
}

.week-menu__item::after {
  right: 0;
}

.week-menu__item:first-of-type::before {
  width: 0;
}

.week-menu__item:last-of-type::after {
  width: 0;
}

@media print {
  .hideOnPrint {
    display: none !important;
    visibility: hidden;
  }
}
