@keyframes rotation {
  0% {
    transform: rotate(-180deg);
  }
  20% {
    transform: rotate(-180deg);
  }
  50% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes upDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}

.partial-logo {
  position: relative;
  animation: upDown 1s infinite ease-in-out;

  .lonely-j {
    position: absolute;
    top: 125px;
    left: 125px;
    transform: rotate(180deg);
    animation: rotation 2s infinite;
  }
}
