.slider {
  align-items: stretch;
  display: flex;
  width: 100%;
}

.slider-prev,
.slider-next {
  color: $dark-gray;
  display: flex;
  justify-content: center;
  width: 60px;
}

.overlay .slider-prev,
.overlay .slider-next {
  color: $white;
}

.slider-content {
  flex: 1;
}
