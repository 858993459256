.choice-eliminated {
  text-decoration: line-through;
  color: #cdd3ce;
}

.highlight-blue {
  cursor: url(../../assets/pointers/highlighter-blue.svg) 0 32, pointer;
}

.highlight-red {
  cursor: url(../../assets/pointers/highlighter-red.svg) 0 32, pointer;
}

.highlight-yellow {
  cursor: url(../../assets/pointers/highlighter-yellow.svg) 0 32, pointer;
}

.highlight-green {
  cursor: url(../../assets/pointers/highlighter-green.svg) 0 32, pointer;
}

.highlight-bg-blue {
  background-color: #00bae060;
}

.highlight-bg-red {
  background-color: #eb575760;
}

.highlight-bg-yellow {
  background-color: #ffd90060;
}

.highlight-bg-green {
  background-color: #55d45560;
}

.hl-dark {
  color: white;
}
