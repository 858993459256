.video-list {
  display: flex;
  height: 428px;
}

.video-list__video {
  background-color: $black;
  height: 100%;
  width: 75%;
}

.video-list__playlist {
  background-color: $light-gray;
  height: 100%;
  overflow-y: auto;
  width: 25%;
}

.video-list__item {
  display: flex;
  height: 83px;
  padding: 0.5em;
  width: 100%;

  &.active {
    background-color: $medium-gray;
  }
}

.video-list__thumb,
.video-list__info {
  height: 100%;
  width: 50%;
}

.video-list__thumb {
  pointer-events: none;
}

.video-list__info {
  padding-left: 0.5em;
  text-align: start;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  min-width: 560px;
}
