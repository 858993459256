@import 'variables';
@import 'mixins';
@import 'transitions';

@for $i from 2 through 6 {
  .line-clamp-#{$i} {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: $i;
    overflow: hidden;
    word-break: break-word;
  }

  .line-clamp-#{$i}-#{$i - 1} {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: $i;
    overflow: hidden;
    word-break: break-word;

    @include device(xs) {
      -webkit-line-clamp: $i - 1;
    }

    @include device(sm) {
      -webkit-line-clamp: $i - 1;
    }
  }
}
