.tag-button {
  align-items: center;
  background-color: $light-gray;
  border-color: $light-gray;
  border-radius: 100px;
  border-style: solid;
  border-width: 2px;
  color: $gray;
  display: flex;
  padding: 0.5em 0.6em;

  &.active {
    background-color: $white;
    border-color: $color-primary;
    color: $color-primary;
  }
}
