.rc-slider-track {
  background-color: $color-primary !important;
  height: 0.5rem !important;
}

.rc-slider-rail {
  background-color: $medium-gray !important;
  height: 0.5rem !important;
}

.rc-slider-handle {
  background-color: $color-primary !important;
  border: solid 2px $color-primary !important;
  cursor: pointer !important;
  height: 1.2rem !important;
  width: 1.2rem !important;
}

.rc-slider-handle:hover {
  border-color: $color-primary !important;
}

.rc-slider-handle:active {
  border-color: $color-primary !important;
  box-shadow: 0 0 5px $color-primary !important;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: $color-primary !important;
  box-shadow: none !important;
}
