.flashcard {
  display: none;
  height: 480px;
  justify-content: center;
  width: 680px;
  z-index: 1;

  &.active {
    animation: rotcard 0.6s linear forwards;
    display: flex;
  }

  &__container {
    display: flex;
    height: 100%;
    overflow: hidden;
    position: relative;
    top: 0;
    transition: width 0.5s ease-in;
    width: 328px;
  }
}

.flashcard.open {
  .flashcard__container {
    width: 100%;
  }
}

.flashcard-front,
.flashcard-back {
  background-color: $color-primary-dark;
  border-radius: 8px;
  color: $white;
  height: 100%;
  position: absolute;
  width: 328px;
}

.flashcard-front {
  left: 0;
  z-index: 1;
}

.flashcard-back {
  right: 0;
}


.mini-flashcard.active {
  animation: rotcard2 0.2s forwards;
  box-shadow: 0 0 2px 1px $medium-gray;
}

@keyframes rotcard {
  0% {
    transform: scale(0.2) rotateY(180deg);
    transform-origin: 50% -20%;
  }

  100% {
    transform: scale(1) rotateY(360deg);
  }
}

@keyframes rotcard2 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1.15);
  }
}
