.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown__menu {
  background-color: $light-gray;
  box-shadow: 0 2px 10px 3px rgba($black, 0.5);
  display: none;
  left: calc(100% - 16em);
  padding: 0.5em;
  position: absolute;
  top: calc(100% + 0.8em);
  width: 16em;
  z-index: 1;

  &.active {
    display: block;
  }

  &::before {
    background-color: inherit;
    content: '';
    height: 1.5em;
    position: absolute;
    right: 1.5em;
    transform: rotate(45deg) translateY(-80%);
    width: 1.5em;
    z-index: -1;
  }
}

.dropdown__item {
  display: flex;
  height: 2em;
  justify-content: stretch;
  padding-left: 0.5em;
  width: 100%;

  > * {
    align-items: center;
    height: 100%;
    justify-content: start;
    line-height: 2em;
    text-align: start;
    width: 100%;
  }

  &:hover {
    background-color: $medium-gray;
  }
}
