h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
small {
  color: inherit;
  display: block;
  font-family: inherit;
  line-height: 1.2;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 900;
}

h1 {
  font-size: 1.75em;
}

h2 {
  font-size: 1.625em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.375em;
}

h5 {
  font-size: 1.25em;
}

h6 {
  font-size: 1.125em;
}

p {
  font-size: 1em;
  line-height: 1.5;
}

small {
  font-size: 0.875em;
}
