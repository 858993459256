.date-picker-close-button::after {
  background-color: #0473E3;
}

.date-picker-day-selected {
  background-color: #0473E3;
  color: #FFFFFF;
}

.date-picker-day-selected:hover {
  background-color: #011224;
  color: #FFFFFF;
}

.date-picker-day-not-selected {
  background-color: #FFFFFF;
  color: #000000;
}

.date-picker-day-not-selected:hover {
  background-color: #0473E3;
  color: #FFFFFF;
}

.date-picker-light::placeholder {
  color: #4B4F54;
}

.date-picker-dark::placeholder {
  color: #aaa;
}

.date-picker-light {
  color: #4B4F54;
}

.date-picker-dark {
  color: #EEE;
}

.date-picker-main {
  padding-left: 10px;
  background-color: transparent;
}

.date-picker-report {
  width: 7.5rem;
  text-transform: capitalize;
}

@keyframes blue-shadow {
  from {
    box-shadow: 0 0 0px 0px #0473E3;
  } to {
    box-shadow: 0 0 2px 2px #0473E3;
  }
}

.select-border:focus-within {
  animation: blue-shadow .2s linear forwards;
}

.date-picker-main:focus-visible {
  outline: transparent;
}

