.difficulty-blocks {
  display: inline-flex;
  justify-content: space-around;
  position: relative;

  > div {
    height: 15px;
    margin-right: 2px;
    width: 14px;
  }

  > :nth-of-type(1),
  > :nth-of-type(2) {
    background-color: $medium-gray;
  }

  > :nth-of-type(1).active,
  > :nth-of-type(2).active {
    background-color: $color-success;
  }

  > :nth-of-type(3),
  > :nth-of-type(4) {
    background-color: $medium-gray;
  }

  > :nth-of-type(3).active,
  > :nth-of-type(4).active {
    background-color: $color-warning;
  }

  > :nth-of-type(5),
  > :nth-of-type(6) {
    background-color: $medium-gray;
  }

  > :nth-of-type(6) {
    margin-right: 0;
  }

  > :nth-of-type(5).active,
  > :nth-of-type(6).active {
    background-color: $color-danger;
  }
}

.difficulty-blocks::before {
  background-color: rgba($white, 0.5);
  content: '';
  height: 4px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: calc(100% - 4px);
}
