.banner {
  background: #FFFFFF;
  display: flex;
  justify-content: center;

  .close-banner {
    display: inline;
    margin-left: 1rem;
  }
}

.banner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65vw;

  .banners {
    display: flex;
    overflow-x: hidden;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;

    .banner-img {
      flex: none;
      width: 100%;
      // scroll-snap-align: start;
      pointer-events: none;

      a {
        line-height: 0;
        margin: auto;
        pointer-events: all;
      }

      img {
        width: 100%;
      }
    }
  }
}

.carousel-buttons {
  position: relative;
  display: flex;
  align-items: center;
  top: -5px;

  .button {
    margin: 0 5px;
    height: 10px;
    width: 10px;
    background: $color-primary;
    opacity: 0.4;
    border-color: transparent;
    border-radius: 50%;
    cursor: pointer;
  }

  .active {
    opacity: 0.8;
  }
}
