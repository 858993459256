$gridsize: 12;

.gridcontainer {
  display: grid;
  grid-template-columns: repeat($gridsize, minmax(0, 1fr));

  // Justifiers
  &__grid-item-justify-stretch {
    justify-self: stretch;
  }

  &__grid-item-justify-center {
    justify-self: center;
  }

  &__grid-item-justify-start {
    justify-self: start;
  }

  &__grid-item-justify-end {
    justify-self: end;
  }

  // Aligners
  &__grid-item-align-start {
    align-self: start;
  }

  &__grid-item-align-end {
    align-self: end;
  }

  &__grid-item-align-center {
    align-self: center;
  }

  &__grid-item-align-stretch {
    align-self: stretch;
  }
}

@for $i from 0 through 8 {
  @include device(xs) {
    .gridcontainer__column-gap--xs-#{$i} {
      grid-column-gap: $i * 0.5em !important;
    }
    .gridcontainer__row-gap--xs-#{$i} {
      grid-row-gap: $i * 0.5em !important;
    }
  }

  @include device(sm) {
    .gridcontainer__column-gap--sm-#{$i} {
      grid-column-gap: $i * 0.5em !important;
    }
    .gridcontainer__row-gap--sm-#{$i} {
      grid-row-gap: $i * 0.5em !important;
    }
  }

  @include device(lg) {
    .gridcontainer__column-gap--lg-#{$i} {
      grid-column-gap: $i * 0.5em !important;
    }
    .gridcontainer__row-gap--lg-#{$i} {
      grid-row-gap: $i * 0.5em !important;
    }
  }

  @include device(xl) {
    .gridcontainer__column-gap--xl-#{$i} {
      grid-column-gap: $i * 0.5em !important;
    }
    .gridcontainer__row-gap--xl-#{$i} {
      grid-row-gap: $i * 0.5em !important;
    }
  }

  @include device(xxl) {
    .gridcontainer__column-gap--xxl-#{$i} {
      grid-column-gap: $i * 0.5em !important;
    }
    .gridcontainer__row-gap--xxl-#{$i} {
      grid-row-gap: $i * 0.5em !important;
    }
  }


  .gridcontainer__column-gap-#{$i} {
    grid-column-gap: $i * 0.5em;
  }
  .gridcontainer__row-gap-#{$i} {
    grid-row-gap: $i * 0.5em;
  }
}

@for $i from 1 through $gridsize {

  @include device(xs) {
    .gridcontainer__grid-item--xs-#{$i} {
      grid-column-end: span $i !important;
    }
  }

  @include device(sm) {
    .gridcontainer__grid-item--sm-#{$i} {
      grid-column-end: span $i !important;
    }
  }

  @include device(lg) {
    .gridcontainer__grid-item--lg-#{$i} {
      grid-column-end: span $i !important;
    }
  }

  @include device(xl) {
    .gridcontainer__grid-item--xl-#{$i} {
      grid-column-end: span $i !important;
    }
  }

  @include device(xxl) {
    .gridcontainer__grid-item--xxl-#{$i} {
      grid-column-end: span $i !important;
    }
  }

  .gridcontainer__grid-item-#{$i} {
    grid-column-end: span $i;
    min-width: 0;
  }

}
