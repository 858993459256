
.w100 {
  width: 100%;
}

.w-full {
  width: 100vw;
}

.h-full {
  height: 100vh;
}

.w50 {
  width: 50%;
}

.w33 {
  width: calc(100% / 3);
}

.w25 {
  width: 25%;
}

.w20 {
  width: 20;
}

.w10 {
  width: 10;
}

.h100 {
  height: 100%;
}

.h50 {
  height: 50%;
}

.h33 {
  height: calc(100% / 3);
}

.h25 {
  height: 25%;
}

.h20 {
  height: 20;
}

.h10 {
  height: 10;
}

.shadow {
  box-shadow: 0 0 8px 2px #0005;
}

.elevated {
  box-shadow: 0 4px 4px rgba(111, 125, 172, 0.25);
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.opaque {
  opacity: 0.5;
}

.d-block {
  display: block;
  width: 100%;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.box-shadow {
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.25);
}
