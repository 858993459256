.zoom {
  box-shadow: 0 0 15px 15px rgba($black, 0.5);
  left: 50%;
  line-height: 0;
  position: fixed;
  top: 50%;
  transform: scale(2.5) translate(-20%) translateY(-20%);
  z-index: 2;
}

.fullbox {
  height: 100%;
  width: 100%;
}
