@keyframes rotate-ball-animation {
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg)
  }

}

.rotate-ball {
  animation: rotate-ball-animation 2s infinite linear;
}
