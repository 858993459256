@mixin hide {
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s linear;
  visibility: hidden;
}

@mixin show {
  opacity: 1;
  transition: opacity 0.3s linear;
  visibility: visible;
}

@mixin scale0 {
  transform: scale(0);
  transition: transform 0.2s linear;
}

@mixin scale1 {
  transform: scale(1);
  transition: transform 0.2s linear;
}
