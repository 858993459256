$color-default: #4b4f54;

$color-primary: #009bbb;
$color-primary2: #469cbb;
$color-primary-light: #00bae0;
$color-primary-dark: #0e7893;
$color-primary-50: #7fcddd;

$color-success: #55d455;
$color-success-light: #e2f0e0;
$color-danger: #eb5757;
$color-danger-light: #f6e2e2;
$color-warning: #ebc756;
$color-warning-light: #f1ebd2;

$black: #000;
$dark-gray: #8a9b8e;
$medium-gray: #cdd3ce;
$light-gray: #eef0ef;
$white: #fff;

$color-video: #d9453c;
$color-apostila: #fd823e;
$color-mapa: #97578c;
$color-flashcard: #3e81a3;
$color-question: #8e72cd;

$red: #eb5757;
$gray: #8a9b8e;
$blue: #3e81a3;
$orange: #f2994a;
$green: #27ae60;
$purple: #bb6bd9;

$bg-video: rgba($color-video, 0.75);
$bg-apostila: rgba($color-apostila, 0.75);
$bg-mapa: rgba($color-mapa, 0.75);
$bg-flashcard: rgba($color-flashcard, 0.75);
$bg-question: rgba($color-question, 0.75);
