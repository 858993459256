.soon__container {
  pointer-events: none;
  position: relative;
}

.soon__content {
  opacity: 0.5;
}

.soon__tag {
  align-items: center;
  background-color: $white;
  border: 3px solid $color-primary;
  border-radius: 8px;
  color: $color-primary;
  display: flex;
  padding: 0.3em;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(10deg);
}
