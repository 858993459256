$ball-height: 1.2em;
$width: 3em;

.toggle {
  background: $medium-gray;
  border-radius: 50px;
  display: inline-block;
  height: calc(#{$ball-height} + 4px);
  position: relative;
  width: calc(#{$width} + 4px);

  &::after {
    background-color: $white;
    border: 2px solid $light-gray;
    border-radius: inherit;
    content: '';
    height: $ball-height;
    left: 2px;
    position: absolute;
    top: 2px;
    transition: left 0.2s linear;
    width: $ball-height;
  }

  &.active::after {
    left: calc(#{$width} - #{$ball-height} + 2px);
  }

  &--primary.active {
    background-color: #0473E3;
  }

  &:focus {
    box-shadow: 0 0 3px 1px $medium-gray;
  }

  &--primary.active:focus {
    box-shadow: 0 0 3px 1px $color-primary;
  }
}
